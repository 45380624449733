import React from "react"
import styled from "styled-components"
import Container from "../components/Container"
import Layout from "../layouts/default"
import Page from "../components/Page"
import SEO from "../components/seo"
import usePageHeader from "../hooks/usePageHeader"
import { H1, H2, P, Table, TableHead, UL, Address } from "../styles"
import { Link } from "gatsby"

const Content = styled(Container)`
  padding-top: 4rem;
  padding-bottom: 8rem;

  ${H2} {
    margin: 8rem 0 4rem 0;
  }

  ${UL} {
    margin: 4rem 2rem;
  }

  ${P},li {
    color: ${({ theme }) => theme.colors.greyLight};
  }
`

const TermsOfServicePage = () => {
  const [pageHeaderRef, pageHeaderHeight] = usePageHeader()
  return (
    <Layout>
      <SEO title="Phi | Privacy Policy" />
      <Page as="main" pageHeaderHeight={pageHeaderHeight}>
        <Page.Header as="header" columnWidth={800} ref={pageHeaderRef}>
          <H1 as={H2}>PHENOMETRY PRIVACY POLICY</H1>
        </Page.Header>
        <Content columnWidth={800} as="article">
          <P>
            For Phenometry P.C. (“Phenometry”, “we”, “us”, “our”), safeguarding
            the privacy of our users is one of our fundamental responsibilities.
            Our Privacy Policy describes how and why we might collect, store,
            use and share your information when you use our services
            (“Services”), i.e. when you access and use our application Phi,
            navigate our website, or engage with us in other related ways,
            including marketing or events.
          </P>
          <P>
            We will take reasonable steps to protect your privacy, consistent
            with the guidelines set forth in this policy and with applicable
            U.S., European Union and other international data privacy laws. In
            this Privacy Policy, the term “personal information” means
            information that allows someone to identify or contact you,
            including, for example, your email address, IP address, name, as
            well as any information that can be reasonably linked to you or
            relates to you.
          </P>
          <P>
            Reading our Privacy Policy will help you understand our
            responsibilities and your privacy rights and choices. If you do not
            agree with our policies and practices, please do not use our
            Services. If there are still questions or concerns, please contact us at {" "}
            <a href="mailto:privacy@phi3d.com">privacy@phi3d.com</a>.
          </P>
          <H2>I. Information We Collect and Receive</H2>
          <P>
            When you access or use our Services, we may receive the following
            kinds of personal information:
          </P>
          <UL>
            <li>
              Log data: When you access Phi, our system automatically records
              data regarding the new session. This data may include your email
              address, Internet Protocol (IP) address and Subscription status.
            </li>
            <li>
              Device data: We also collect device data, such as your operating
              system or browser type.
            </li>
            <li>
              Geo-location information: Your Internet Protocol (IP) address may
              be used to determine approximate location.
            </li>
            <li>
              Usage data: This may include information about your activity in
              the Services, such as event or error reports.
            </li>
            <li>
              Payment and Billing information: For the purchase of paid subscriptions, payment-related information may be requested. We use the service of Stripe, Inc (“Stripe”), and its affiliates for our payment processing. Stripe may use your personal data (e.g. credit card number, the amount and the date of payment) to process and authenticate the online payment transactions, or obtain and process personal information about you received through the services or from third parties in order to determine your identity and prevent fraudulent activities. In addition, if you wish to receive a receipt of your payment transaction from Stripe, you can opt to enter your name, email and billing address. We may have access to some of the aforementioned data, for example payment card type, the last 4 digits of payment card, or the billing address.
            </li>
            <li>
              Information from third parties: We may also receive data from
              third parties, such as your Onshape user ID, preferred length
              units or the type of document you are in (public or private).
            </li>
          </UL>
          <P>
            If you are a student or an educator applying for an academic
            license, you are asked to provide some personal information as part
            of your application, in addition to the above. We may also receive
            information from public databases or other outside sources in order
            to process your application and provide the free license you applied
            for.
          </P>
          <H2>II. Legal Basis for Processing your Information</H2>
          <P>
            We rely on the following legal grounds to process your personal
            information:
          </P>
          <UL>
            <li>
              Performance of a contract. When you access our Services, we might
              need to collect and use your personal information to perform our
              agreement with you to deliver the Service as described in this
              Privacy Policy and our Terms of Service.
            </li>
            <li>
              Consent. We may use some of your personal information if you have
              given permission to use your personal information for a specific
              purpose. You can withdraw your consent at any time.
            </li>
            <li>
              Legitimate interests. We may use your personal information for our
              legitimate interests to improve our Services. Your fundamental
              rights and freedoms always override our interests.
            </li>
          </UL>
          <H2>III. How we use your information</H2>
          <P>We use your information for the following purposes:</P>
          <UL>
            <li>
              Providing our Services. We use information you provide to
              authenticate you, and enable you to use our cloud-based services
              For example, data is stored in localhost (on your device) and in a
              database on our server, so that we can recall them for you at
              future sessions.
            </li>
            <li>
              Communicating with you: We might send you occasional emails with
              updates related to Phi, such as the release of a new version, a
              webinar, or other information we believe you might be interested
              in. You can opt out by clicking the relevant link at the bottom of
              every such e-mail. We may also send you emails regarding
              administrative or other important issues we need to communicate to
              you.
            </li>
            <li>
              Statistical or anonymous analysis: We may also use your
              information to improve our Services, by eliciting use patterns or
              possible complications that a number of users could encounter. In
              this case the data will be anonymized, unless we imperatively need
              to communicate with specific users in order to solve a technical
              issue.
            </li>
            <li>
              Compliance and investigations: We reserve the right to use data in
              connection with suspected inappropriate use, fraud, violations of
              our Terms of Service, or any other activity that threatens to
              impede our users’ ability to use our Services efficiently and
              effectively.
            </li>
          </UL>
          <H2>IV. Sharing and Disclosure</H2>
          <P>
            We do not sell or rent your personal information to any third party.
            We may share information in specific situations described below,
            with the parties mentioned:
          </P>
          <UL>
            <li>
              Support: To provide you with customer support, our staff may need
              to share among themselves information you have previously provided
              to us.
            </li>
            <li>
              Partners and Affiliates: In case you access our Services through
              Onshape or other Phenometry partners (“Partners”) and their
              respective software platforms, your information and intellectual
              property might need to be shared with that Partner as part of
              performing our Service, for example when exporting your model.
            </li>
            <li>
              Vendors and other Third-Party Service Providers: We may share your
              data with third-party vendors, service providers, contractors or
              agents (“third parties”) who perform services for us or on our
              behalf and require access to such information to do that work. We
              have contracts in place with our third parties, which are designed
              to help safeguard your personal information. The third parties we
              may share your information with are listed in the table below.
            </li>
            <li>
              Business Transfers: We may transfer your information as a result
              of acquisition of all or a portion of our business to another
              company.
            </li>
            <li>
              Legal Compliance, Public Protection and integrity of our Services:
              We might share your information in case this is required by law,
              legal request, or lawful request of a public authority, or to
              protect the safety, rights and/or property of the public or any
              person, or to detect, prevent, or address fraud or security
              issues.
            </li>
          </UL>
          <TableHead>Data Disclosure to Third Parties</TableHead>
          <Table>
            <thead>
              <tr>
                <th>Vendor</th>
                <th>Purpose</th>
                <th>
                  Link to Vendor's
                  <br />
                  Privacy Policy
                </th>
                <th>Disclosed Personal Data</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Amazon Web Services (AWS)</td>
                <td>Cloud Hosting</td>
                <td>
                  <a
                    href="https://aws.amazon.com/compliance/data-privacy-faq/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://aws.amazon.com/compliance/data-privacy-faq/
                  </a>
                </td>
                <td>PI and Phi settings (all data encrypted)</td>
              </tr>
              <tr>
                <td>Stripe</td>
                <td>Payment Processing</td>
                <td>
                  <a
                    href="https://stripe.com/en-gr/legal/privacy-center"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://stripe.com/en-gr/legal/privacy-center
                  </a>
                </td>
                <td>Payment and billing data</td>
              </tr>
              <tr>
                <td>Mailchimp</td>
                <td>Email Communication</td>
                <td>
                  <a
                    href="https://mailchimp.com/legal/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://mailchimp.com/legal/
                  </a>
                </td>
                <td>CRM data</td>
              </tr>
              <tr>
                <td>Vimeo</td>
                <td>Analytics of embedded videos</td>
                <td>
                  <a
                    href="https://vimeo.com/privacy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://vimeo.com/privacy
                  </a>
                </td>
                <td>Analytics data</td>
              </tr>
            </tbody>
          </Table>
          <P>
            Additionally, if you are using the Phenometry Services under an
            educational/academic license, you further acknowledge and agree that
            as a condition of this educational license Your Content (as defined
            in our Terms of Service) can be automatically shared with
            Phenometry, through a Phenometry account, a Phenometry Onshape
            account or other Phenometry Partner account depending on how you use
            the Phenometry Services. Phenometry will have view-only access to
            Your Content and may use it for the purpose of improving Phi and the
            Phenometry Services, and providing user support. Phenometry will not
            publicize or share Your Content with third parties without your
            written permission.
          </P>
          <H2>V. Your rights</H2>
          <P>
            In some regions, such as the EEA, UK, Canada or California, you have
            certain rights under applicable data protection laws. These may
            include the right:
          </P>
          <UL>
            <li>
              to request access and obtain a copy of your personal information;
            </li>
            <li>
              to request rectification or erasure; to restrict the processing of
              your personal information;
            </li>
            <li>
              to transfer your personal data to another controller, if
              applicable;
            </li>
            <li>
              to object to the processing of your personal information; not to
              be subject to a decision based solely on automated processing,
              including profiling, which produces legal effects ("Automated
              Decision-Making"). Automated Decision-Making currently does not
              take place on our websites or in our services;
            </li>
            <li>
              to the extent we base the collection, processing and sharing of
              your personal data on your consent, to withdraw your consent at
              any time, without affecting the lawfulness of the processing based
              on consent before its withdrawal.
            </li>
          </UL>
          <P>
            To exercise your rights (request an accounting of your personal
            information, a change to your personal information, deletion of your
            personal information, or to withdraw your consent to the collection,
            processing, and/or transfer of your personal information), please contact us at <a href="mailto:privacy@phi3d.com">privacy@phi3d.com</a>. We will
            respond to your request according to applicable law. Please note
            that you might be required to verify your identity, to protect your
            privacy and security.
            <br />
            Once we have received notification of your consent withdrawal, and
            provided there are no compelling legitimate grounds for further
            processing or for the establishment, exercise or defense of legal
            claims, we will no longer process your information for the purposes
            to which you originally consented.
          </P>
          <H2>VI. Changes to Privacy Policy</H2>
          <P>
            This privacy notice may be updated from time to time. If we make
            changes that we consider material, we may notify you either by
            prominently posting a notice of such changes, or by directly sending
            you a notification.
          </P>
          <H2>VII. Retention and Deletion</H2>
          <P>
            You have the right to request access to the personal information we
            collect from you, change that information or delete it. Please contact us at <a href="mailto:privacy@phi3d.com">privacy@phi3d.com</a> to submit
            your request.
          </P>
          <H2>VIII. Questions or Concerns</H2>
          <P>
            Any questions or comments about this notice should be addressed to:
          </P>
          <Address>
            <p>
              Phenometry P.C.
              <br />
              6 Apollonos str.
              <br />
              10557,
              <br />
              Athens Greece
            </p>
          </Address>
          <P>
            or communicated at <a href="mailto:privacy@phi3d.com">privacy@phi3d.com</a>.
          </P>
        </Content>
      </Page>
    </Layout>
  )
}

export default TermsOfServicePage
